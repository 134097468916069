//@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+SC&family=Noto+Serif+TC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@500&family=Noto+Serif+TC:wght@500&display=swap');

.matcher-game {
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.game-title {
  position: absolute;

  width: 100vw;
  text-align: center;

  font-family: 'Roboto', sans-serif;

  --title-height: .6;
  top: calc((var(--top-space) * (1 - var(--title-height))) / 2);
  font-size: calc(var(--top-space) * var(--title-height));
}

.game-score {
  position: absolute;
}

.game-score__previous-wrong {
  background-color: indianred;
}

.game-score__previous-correct {
}

//
//.options-bar {
//  background-color: blue;
//  position: absolute;
//  top: 0;
//  left: 0;
//  height: var(--top-space);
//  width: 100%;
//}

.game-body {
  //background-color: green;
  position: absolute;
  top: var(--top-space);
  left: 0;
  height: calc(100vh - var(--top-space));
  width: 100vw;

  // Modified by the media query below to choose whether we're in flat or tall configuration
  --response-rows: 2;
  // The content width you use on your website
  --content-width: 90vw;
  --default-margin: calc((100vw - var(--content-width)) / 2);

  // The number of columns
  --response-columns: calc(4 / var(--response-rows));
  // The size of the gutter
  --gutter: 10px;
  // This is the calculation for the row height.
  --response-box-dimensions: calc(
          (var(--content-width) - (var(--gutter) * (var(--response-columns) - 1))) / var(--response-columns)
  );
}

@media (min-aspect-ratio: 7/10) {
  .game-body {
    --response-rows: 1;
  }
}

.quiz-hint {
  display: flow;
  width: var(--content-width);
  margin-inline: auto;

  //--available-height: calc(100vh - var(--default-margin) - var(--gutter) - var(--top-space) - (var(--response-box-dimensions) * var(--response-rows)));
  --available-height: calc(100vh - var(--default-margin) - 20px - var(--top-space) - (var(--response-box-dimensions) * var(--response-rows)));

  font-size: clamp(16px, 50px, 220px);

  height: var(--available-height);

  border: yellow solid medium;
  border-radius: 15px;

  color: black;
}

.quiz-response-panel {
  //position: absolute;
  //left:10vw;


  margin-inline: auto;
  width: min(90vw, 1000px);
  //border: 3px solid hotpink;

}

.quiz-response-grid {
  position: absolute;
  bottom: 5vw;

  display: grid;
  width: 100%;
  max-width: var(--content-width);
  grid-template-columns: repeat(var(--response-columns), 1fr);
  grid-auto-rows: var(--response-box-dimensions);

  grid-column-gap: var(--gutter);
  grid-row-gap: var(--gutter);
}

.quiz-response-option {
  border: black solid medium;
  border-radius: 15px;

  cursor: pointer;

  background-color: rgb(181, 226, 80);
  //align-content: space-evenly;
}

// This works better than hover if we're on a touchscreen
.quiz-response-option:active {
  background-color: lightblue;
}

.quiz-response-option.quiz-response-option__ChineseSimplified,
.quiz-response-option.quiz-response-option__ChineseTraditional {
  font-family: 'Noto Serif SC', serif, 'Noto Serif TC', serif;
  font-size: calc(var(--response-box-dimensions) / clamp(1, var(--character-count) * 1.35, 10));
  line-height: calc(var(--response-box-dimensions) / 1.15);

}

.quiz-response-option.quiz-response-option__English,
.quiz-response-option.quiz-response-option__Pinyin {
  font-family: Roboto, sans-serif;
  font-size: clamp(5vw, calc(7 * var(--response-box-dimensions) / var(--string-width)), 32vw);
}
