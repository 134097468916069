.App {
    /*color: white;*/
    min-height: 100vh;

    --top-space: 10vh;

    background-color: yellowgreen;
}

/*#ruler {*/
/*    visibility: hidden;*/
/*    white-space: nowrap;*/
/*    !* Normalized measure for font height. *!*/
/*    font-size: 20px;*/
/*}*/

div,
button
{
    /*-webkit-tap-highlight-color: rgba(0, 0, 0, 0);*/
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

/*.App-header {*/
/*  background-color: #282c34;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/
