h2 {
    margin-top: 1vw;
    margin-bottom: 1vw;
}

.options-panel {
    /*height: 90%;*/
    --font-size-default: 4vw;
    font-size: var(--font-size-default);
    text-align: left;
}

.options-panel__flex {
    display: flex;
    flex-direction: column;
}

.options-panel__section-name {
    font-weight: bold;
    margin: 3vw 0 0 0;
}

.options-panel__section {
    margin-top: 4px;

    border: black solid 4px;
    border-radius: 3vw;
}

.options-panel__section__inner {
    display: flex;
    flex-direction: column;
    /*margin-left: 5vw;*/
    margin: 6px;
}

.options-panel__section__active {
    border-color: limegreen;
}

.options-panel__word-list-selector {
    /*position: absolute;*/
    /*left: 5vw;*/

}

.options-panel select {
    font-size: var(--font-size-default);
}

.options-panel input[type='checkbox'],
.options-panel input[type='radio']{
    transform: scale(1.6);
    /*vertical-align: center;*/
}